<template>
    <Layout>
        <Form @submit.prevent="salvar" ref='form'>
            <Col :responsive="[6,6,6,6,6]">
            <FormCheckboxRadio className="form-check-inline">
                <CheckboxRadio type="radio" id="pf" name="radio" label="Pessoa Física" :checked="pf" @click="toggleForm(true)" @change="changeCkb()" />
            </FormCheckboxRadio>
            <FormCheckboxRadio className="form-check-inline">
                <CheckboxRadio type="radio" id="pj" name="radio" label="Pessoa Jurídica" :checked="!pf" @click="toggleForm(false)" @change="changeCkb()" />
            </FormCheckboxRadio>
            </Col>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                <Input :label="pf ? 'Nome' : 'Nome da Empresa'" type="text" v-model="cliente.Nome_RazaoSocial" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input :mask="pf ? '###.###.###-##' : '##.###.###/####-##'" :label="pf ? 'CPF' : 'CNPJ'" type="text" v-model="cliente.CPF_CNPJ" @blur="validarCPFCNPJ()" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input :label="pf ? 'Data de Nascimento' : 'Data de Abertura'" type="date" v-model="cliente.DatadeAbertura" />
                </Col>
                <Col :responsive="[12,6,5,4,2]">
                <Select label="Situação" v-model="cliente.ativo" :options="status_ativo"/>
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Endereço" type="text" v-model="cliente.Endereco" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Nº" type="text" v-model="cliente.Numero" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Bairro" type="text" v-model="cliente.Bairro" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Cidade" type="text" v-model="cliente.Cidade" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="UF" type="text" v-model="cliente.UF" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="CEP" :mask="'#####-###'" type="text" v-model="cliente.CEP" @blur="APICEP()" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Contato 1" :mask="[ '(##) ####-####', '(##) #####-####']" type="text" v-model="cliente.Telefone" />
                </Col>
                <Col :responsive="[12,6,5,4,3]">
                <Input label="Contato 2" :mask="[ '(##) ####-####', '(##) #####-####']" type="text" v-model="cliente.Whatsapp" />
                </Col>
                <Col :responsive="[12,6,6,6,4]">
                <Input v-if="!pf" label="Nome do Representante Legal" type="text" v-model="cliente.RepresentanteLegal" />
                </Col>
                <Col :responsive="[12,6,6,6,4]">
                <Input v-if="!pf" label="CPF" type="text" v-model="cliente.CPF_RepresentanteLegal" />
                </Col>
                <Col :responsive="[12,6,6,6,4]">
                <Input v-if="!pf" label="Contato do Representante Legal" :mask="[ '(##) ####-####', '(##) #####-####']" type="text" v-model="cliente.Contato_RepresentanteLegal" />
                </Col>
                <Col :responsive="[12,6,6,6,6]">
                <Input label="RG" type="text" v-model="cliente.RG" />
                </Col>
                <Col :responsive="[12,6,6,6,6]">
                <Input label="Email" type="text" v-model="cliente.Email" />
                </Col>
                <Col :responsive="[12,6,6,6,6]">
                <Input label="Nome da Mãe" type="text" v-model="cliente.Nome_Mae" />
                </Col>
                <Col :responsive="[12,12,12,12,12]">
                <Checkbox label="Whatsapp" v-model="cliente.fatura_whatsapp" />
                </Col>
                <Col :responsive="[12,12,12,12,12]">
                <Checkbox label="Email" v-model="cliente.fatura_email" />
                </Col>
                <Col :responsive="[12,12,12,12,12]">
                <Checkbox label="Correios" v-model="cliente.fatura_correios" />
                </Col>
                <Col :responsive="[12,6,6,6,6]">
                <Select label="Situação Financeira do Cliente" v-model="cliente.bloqueado" :options="situacao_financeira"/>
                </Col>
                <Col :responsive="[12,6,6,6,6]">
                <Input v-if="cliente.criado_em" label="Data do Cadastro" type="date" v-model="cliente.criado_em" :disabled="true"/>
                </Col>

            </Row>

            <Col :responsive="[12,6,5,4,2]">

            </Col>
            <button class="btn btn-grad btn-brax btn-block mt-2 p-2" type="submit">Salvar</button>
        </Form>
    </Layout>
</template>

<script>
import { computed } from "vue";
import { usePage } from "@inertiajs/inertia-vue3";
import Layout from "../../Layouts/Admin.vue";
import Form from "../../components/Form.vue";
import FormRow from "../../components/Forms/FormRow.vue";
import FormCheckboxRadio from "../../components/Forms/FormCheckboxRadio.vue";
import Row from "../../components/_partes/RowComponent.vue";
import Col from "../../components/_partes/ColComponent.vue";
import CheckboxRadio from "../../components/_partes/CheckboxRadioComponent.vue";
import Checkbox from "../../components/_partes/CheckboxComponent.vue";
import Input from "../../components/_partes/InputComponent.vue";
import Select from "../../components/_partes/SelectComponent.vue";
import { validate } from "validator-cpf-cnpj";
import axios from "axios";

export default {
    components: {
        Layout,
        Form,
        FormRow,
        FormCheckboxRadio,
        Row,
        Col,
        CheckboxRadio,
        Checkbox,
        Input,
        Select,
    },
    props: ["dados"],
    data() {
        return {
            cliente: this.dados ? this.dados : {
                "CPF_CNPJ": "129.257.886-61",
                "Nome_RazaoSocial": "IGOR OGURA RAMOS",
                "DatadeAbertura": "1995-12-05",
                "Apelido_NomeFantasia": "LUCAS",
                "Endereco": "RUA DAS MAGNOLIAS",
                "Numero": "95",
                "PontodeRef": "CASA",
                "Cidade": "PARAGUAÇU",
                "Bairro": "CENTRO",
                "UF": "MG",
                "CEP": "37120-000",
                "Telefone": "(35) 99819-9000",
                "Email": "lucas@braxtelecom.com",
                "RepresentanteLegal": "LUCAS DE BRITO SABINO",
                "CPF_RepresentanteLegal": "129.257.886-61",
                "Contato_RepresentanteLegal": "(35) 9981-99000",
                "Responsavel_pelo_Servico": "LUCAS DE BRITO SABINO",
                "CPF_Responsavel_pelo_Servico": "129.257.886-61",
                "Contato_Responsavel_pelo_Servico": "(35) 9981-99000",
                "Whatsapp": "(35) 99819-9000",
                "RG": "19679055",
                "Nome_Mae": "JERUSA DO CARMO BRITO",
                "ativo": 1,
                "fatura_email": 0,
                "fatura_whatsapp": 0,
                "fatura_correios": 0,
                "credito": "0.00",
                "criado_em": "2020-12-05",
                "atualizado_em": "2022-08-03T14:47:41.000000Z",
                "bloqueado": 0,
                "deleted_at": null,
                "vendedor_id": 3,
                "vendedor": {
                    "id_usuario": 3,
                    "usuario": "12925788661",
                    "nome": "Lucas de Brito Sabino ",
                    "email": "lucas@braxtelecom.com",
                    "nivel": 99,
                    "ativo": 1,
                    "CPF": "12925788661",
                    "criado_em": null,
                    "atualizado_em": "2021-11-08 21:41:16",
                    "vendedor_id": 3
                }
            },
            pf: true,
            cpf_cnpj_validado: this.dados ? true : false,
            status_ativo: [
                {id:0,status: "Inativo"},
                {id:1,status: "Ativo"}
            ], 
            situacao_financeira: [
                {id:0,status: "Liberado"},
                {id:1,status: "Bloqueado"},
                {id:2,status: "A Bloquear"},
                {id:3,status: "Desbloquear"},
            ],
        };
    },
    methods: {
        toggleForm(boolean) {
            this.pf = boolean;
        },
        salvar(e) {
            if (
                this.$refs.form.validateForm(e.target) &&
                this.cpf_cnpj_validado
            ) {
                if (this.cliente.id_cliente) {
                    this.$inertia.put(
                        route("clientes.update", { id: this.cliente.id_cliente }),
                        this.cliente,
                        {
                            preserveScroll: true,
                            resetOnSuccess: false,
                        }
                    );
                } else {
                    this.cliente.vendedor_id = this.user.id_usuario;
                    this.cliente.redirecionar = true
                    this.$inertia.post(route("clientes.store"), this.cliente, {
                        preserveScroll: true,
                        resetOnSuccess: false,
                    });
                }
            }
            if (!this.cpf_cnpj_validado) {
                alert("CPF/CNPJ Inválido");
            }
        },
        validarCPFCNPJ() {
            if (this.cliente.CPF_CNPJ != null) {
                let validado = validate(this.cliente.CPF_CNPJ);
                if (validado) {
                    this.cpf_cnpj_validado = true;
                } else {
                    this.cpf_cnpj_validado = false;
                }
                if (this.cliente.cpf_cnpj.length > 14) {
                    this.APIConsultarCNPJ();
                }
            }
            //
        },
        changeCkb() {
            this.cpf_cnpj_validado = false;
        },
        async APIConsultarCNPJ() {
            if(confirm("Deseja Importar o CNPJ?")){
                let { data } = await axios.get(
                    route("apiCNPJ", {
                        cnpj: this.cliente.cpf_cnpj.replace(/[^0-9]/g, ""),
                    })
                );
                this.cliente.data_nascimento_abertura = data.abertura
                    .split("/")
                    .reverse()
                    .join("-");
                console.log(data)
                let cep = data.cep.replace(/[^0-9]/g, "")
                this.cliente.nome_razao_social = data.nome;
                this.cliente.CEP = cep.substr(0,5)+'-'+cep.substr(5);
                this.cliente.bairro = data.bairro;
                this.cliente.endereco = data.logradouro;
                this.cliente.cidade = data.municipio;
                this.cliente.UF = data.uf;
                this.cliente.numero = data.numero;
                this.cliente.email = data.email;
                this.cliente.contato_um = data.telefone;
                this.cliente.contato_dois = '';
                this.cliente.contato_dois = '';
            }
        },
        async APICEP() {
            if(confirm("Deseja Importar o CEP?")){
                const self = this
                let { data } = await axios.get(
                    route("apiCEP", { cep: (self.cliente.CEP).replace(/[^0-9]/g, "") })
                );
                self.cliente.Bairro = data.bairro
                self.cliente.Cidade = data.localidade
                self.cliente.Endereco = data.logradouro == '' ? self.cliente.Endereco : data.logradouro
                console.log(data)
            }

        },
        async filterStatus(){
            let self = this
            let status_ativo_old = self.status_ativo
            let situacao_financeira_old = self.situacao_financeira
            self.status_ativo = []
            self.situacao_financeira = []
            status_ativo_old.forEach(function(status){
                self.status_ativo.push({
                    value: status.id,
                    descricao: status.status,
                    bool: self.cliente.ativo == status.id ? true : false
                }) 
            })
            situacao_financeira_old.forEach(function(status){
                self.situacao_financeira.push({
                    value: status.id,
                    descricao: status.status,
                    bool: self.cliente.ativo == status.id ? true : false
                }) 
            })
        },
    },
    async mounted() {
        let possuiDados = this.dados ? true : false;
        if (possuiDados) {
            this.pf = this.dados.CPF_CNPJ.length > 14 ? false : true;
            this.cliente.criado_em = this.cliente.criado_em.split('T')[0].split('/').reverse().join('-')
        }
        this.filterStatus()
    },
    setup() {
        //console.log(usePage().props.value)
        const user = computed(() => usePage().props.value.auth.user);
        return { user };
    }
};
</script>

<style>
</style>
