<template>
  <div class="row">
      <slot/>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
