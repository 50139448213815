import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import funcoesGlobais from './custom.js';

require('./bootstrap');


InertiaProgress.init({
    color: '#ff6e0e',
})


let mixin = {
    data(){
        return {
            hojeBR: funcoesGlobais.hojeBR(),
            hojeMysql: funcoesGlobais.hojeMysql(),
            primeiroDiaMes: funcoesGlobais.primeiroDiaMes(),
            ultimoDiaMes: funcoesGlobais.ultimoDiaMes(),
        }
    },
    methods: {
        route,
    }
}


createInertiaApp({
  resolve: name => require(`./Pages/${name}`),
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .mixin(mixin)
      .mount(el)
  },
})
