<template>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)">
        <label class="form-check-label" for="flexCheckDisabled">
            {{label}}
        </label>
    </div>
</template>

<script>
export default {
    emits: ["update:modelValue"],
    props:{
        label: String,
        modelValue: Number
    },
    data() {
        return {
            content: this.value,
        };
    },
    methods: {
        handleInput() {
            console.log(this.content)
            this.$emit("input", this.content);
        },
    },
};
</script>

<style>
</style>
