<template>
    <Layout>
        <h1>LISTA</h1>
        <Row class="row d-flex justify-content-end">
            <Col :responsive="[12,6,5,4,4]">
                <Input placeholder="PESQUISA..." v-model="pesquisa" @customKeyup="pesquisar"/>
            </Col>
        </Row>
        <Row class="row d-flex justify-content-end pt-2">
            <Col :responsive="[12,6,5,4,2]">
                <Input type="date" v-model="inicio"/>
            </Col>
            <Col :responsive="[12,6,5,4,2]">
                <Input type="date" v-model="fim"/>
            </Col>
        </Row>
        <Row class="row d-flex justify-content-end pt-2">
            <Col :responsive="[12,12,12,12,12]">
                <TableComponent :ths="['ACESSO', 'CADASTRO']"
                :dados="acessos"
                :href="route('acessos.index')"
                />
            </Col>
        </Row>

    </Layout>
</template>

<script>
import Layout from "../../Layouts/Admin.vue";
import Input from "../../components/_partes/InputComponent.vue";
import Row from "../../components/_partes/RowComponent.vue";
import Col from "../../components/_partes/ColComponent.vue";
import TableComponent from "../../components/_partes/TableComponent.vue";
export default {
    props:['dados'],
    data(){
        return{
            pesquisa: '',
            inicio: '',
            fim: '',
            acessos: {}
        }
    },
    components: {
        Layout,
        Input,
        Row,
        Col,
        TableComponent,
    },
    methods:{
        pesquisar(){
            let pesquisa = this.pesquisa.toLowerCase()
            const trs = [...document.querySelectorAll('table tbody tr')];
            trs.forEach(el => {
                const matches = el.textContent.toLowerCase().includes(pesquisa);
                el.style.display = matches ? '' : 'none';
            });
            //console.log(pesquisa)
        }
    },
    mounted(){
        this.inicio = this.primeiroDiaMes
        this.fim = this.ultimoDiaMes
        console.log(this.dados)
        console.log(this.hojeBR)
        this.acessos = this.dados.map(function(d){
            let date = new Date(d.criado_em)
            return {
                id: d.id_acesso,
                acesso: d.Acesso, 
                created_at: date.toLocaleDateString()
            }
        })
    }

}
</script>

<style>

</style>
