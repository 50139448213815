const funcoesGlobais = {
    hojeBR() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let dataAtual = dia + '/' + mes + '/' + ano;
        return dataAtual;
    },
    hojeMysql() {
        return this.hojeBR().split('/').reverse().join('-')
    },
    primeiroDiaMes() {
        let date = new Date();
        let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        return primeiroDia.toISOString().split('T')[0]
    },
    ultimoDiaMes() {
        let date = new Date();
        let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return ultimoDia.toISOString().split('T')[0]
    }

}

export default funcoesGlobais;

