<template>
    <Layout>
        <div class="row">
            <div class="col-sm">
                <TableComponent :ths="['ID','Nome']" :tds="[['1','Igor'],['2','Igor 2'],['3','Igor 3']]"/>
            </div>
            <div class="col-sm">
                <TableComponent :ths="['ID','OS']" :tds="[['1','Concertar'],['2','Arrumar 2'],['3','Configurar 3']]"/>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "./Layouts/Admin.vue";
import TableComponent from "./components/_partes/TableComponent.vue";
export default {
    components: {
        Layout,
        TableComponent,
    },
};
</script>

<style>

</style>
