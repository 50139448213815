<template>
    <input class="form-check-input" :type="type" :value="value" :id="id" :name="name" :checked="checked" @click="buttonClickHandler"
    @change="$emit('change')"


    >
    <label class="form-check-label" :for="id">
        {{label}}
    </label>
</template>

<script>
export default {
    props: ["label", "id", "type", "value", "name", "checked"],
    methods: {
        buttonClickHandler() {
            this.$emit("click");
        },
    },
    emits: ['click','change'],
};
</script>

<style>
.form-check-input:checked {
    background-color: var(--brax-secondary);
    border-color: var(--brax-secondary);
}

.form-check-input:focus {
    border-color: var(--brax-secondary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 110 14 / 25%);
}
</style>
