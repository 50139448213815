<template>
    <BootstrapIcon
    :icon="icon"
    :size="size"
    @click="$emit('click',$event)"
    />
</template>

<script>
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
export default {
    emits: ["click"],
    components:{
        BootstrapIcon,
    },

    props:[
        'icon',
        'size',
        'flip-v'
    ]
}
</script>

<style>

</style>