<template>
    <table class="table table-dark table-striped">
        <thead>
            <tr>
                <th scope="col" v-for="th,thKey in ths" :key="thKey">{{th}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="tr,trKey in dados" :key="trKey" @dblclick="abrir" :data-id="tr.id">
                <td v-show="tdKey != 'id'" v-for="td,tdKey in tr" :key="tdKey">{{td}}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props:['ths','href','dados'],
    mounted(){
        //console.log(this.href)
    },
    methods:{
        abrir(e){
            let id = e.target.parentElement.getAttribute('data-id')
            this.$inertia.get(this.href + "/cadastro/" +id)
        }
    }
};
</script>

<style>
.table > :not(:first-child) {
    border-top: 2px solid white;
}

tbody, td, th{
    border: 1px solid white;
    cursor: pointer;
}
</style>
