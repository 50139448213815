<template>
    <label>{{label}}</label>
    <select class="form-select" aria-label="Default select example" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <option v-for="option,key in options" :key="key" :value="option.value">{{option.descricao}}</option>
    </select>
</template>

<script>
export default {
    emits:['update:modelValue'],
    props:['label','options','modelValue'],
};
</script>

<style>
</style>
