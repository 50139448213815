<template>
    <Layout>
        <Form @submit.prevent="salvar" ref='form'>
            <Row>
                    <Col :responsive="[11,5,4,3,3]">
                    <Input label="" type="hidden" v-model="acesso.cliente.id"/>
                    <Input label="Cliente" type="text" v-model="acesso.cliente.nome_razao_social" :disabled="true"/>
                </Col>                
                <Col :responsive="[1,1,1,1,1]">
                    <Icon class="lupa" icon="search" size="md" @click="consultarCliente()"/>
                </Col>
                <Col :responsive="[11,5,4,3,3]">
                    <Input label="Operadora" type="text" v-model="acesso.operadora.operadora" :disabled="true"/>
                </Col>    
                <Col :responsive="[1,1,1,1,1]">
                    <Icon class="lupa" icon="search" size="md" @click="consultarOperadora()"/>
                </Col>            
            </Row>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Tipo Plano" type="text" v-model="acesso.operadora.tipo_plano" :disabled="true"/>
                </Col>  
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Numero da Linha" :mask="[ '(##) ####-####', '(##) #####-####']" type="text" v-model="acesso.numero_linha"/>
                </Col>                
                <Col :responsive="[12,6,5,4,2]">
                <Select label="Situação" v-model="acesso.situacao_id" :options="situacao"/>
                </Col>
            </Row>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Descrição do Plano" type="text" v-model="acesso.operadora.descricao_plano" :disabled="true"/>
                </Col>  
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Voz" type="text" v-model="acesso.operadora.voz" :disabled="true"/>
                </Col>                
                <Col :responsive="[12,6,5,4,2]">
                <Input label="Dados" type="text" v-model="acesso.operadora.dados" :disabled="true"/>
                </Col>
            </Row>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Valor do Plano" name="valor_plano" :mask="['#,##','##,##','###,##','#.###,##']" v-model="acesso.operadora.valor_plano" :disabled="true"/>
                </Col>  
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Desconto" type="text" name="desconto" :mask="['#,##','##,##','###,##','#.###,##']" v-model="acesso.desconto" @change="changeValor($event)"/>
                </Col>    
                <Col :responsive="[12,6,5,4,2]">
                <Input label="Valor Total" type="text" name="valor_total" :mask="['#,##','##,##','###,##','#.###,##']" v-model="acesso.valor_total" @change="changeValor($event)"/>
                </Col>                
                <Col :responsive="[12,6,5,4,2]">
                <Input label="Dia de Vencimento" type="text" v-model="acesso.dia_vencimento"/>
                </Col>
            </Row>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                <Input label="Observação" v-model="acesso.observacao"/>
                </Col>  
            </Row>
            <Row>
                <Col :responsive="[6,6,6,6,6]">
                <label>Tipo de Amplimax:</label>
                <FormCheckboxRadio className="form-check">
                    <CheckboxRadio type="radio" id="null" name="radio" label="Nenhum" :checked="acesso.tipo_amplimax == null ? true : false" @click="toggleForm()"/>
                </FormCheckboxRadio>
                <FormCheckboxRadio className="form-check">
                    <CheckboxRadio type="radio" id="amplimax" name="radio" label="Amplimax" :checked="acesso.tipo_amplimax == 'amplimax' ? true : false" @click="toggleForm()"/>
                </FormCheckboxRadio>
                <FormCheckboxRadio className="form-check">
                    <CheckboxRadio type="radio" id="box" name="radio" label="Box" :checked="acesso.tipo_amplimax == 'box' ? true : false" @click="toggleForm()"/>
                </FormCheckboxRadio>
                <FormCheckboxRadio className="form-check">
                    <CheckboxRadio type="radio" id="roteador" name="radio" label="Roteador" :checked="acesso.tipo_amplimax == 'roteador' ? true : false" @click="toggleForm()"/>
                </FormCheckboxRadio>
                </Col>
            </Row>
            <Row>
                <Col :responsive="[12,6,5,4,4]">
                    <Input label="Ip de Acesso ao Equipamento" v-model="acesso.ip_acesso_equipamento"/>
                </Col>  
            </Row>
            <Row class="justify-content-end">
                <Col :responsive="[12,6,5,4,4]">
                    <Input v-if="acesso.created_at" type="date" label="Data de Cadastro do Acesso" v-model="acesso.created_at" :disabled="true"/>
                </Col>  
            </Row>
            <button class="btn btn-grad btn-brax btn-block mt-2 p-2" type="submit">Salvar</button>
        </Form>
    </Layout>
</template>

<script>
import { computed } from "vue";
import { usePage } from "@inertiajs/inertia-vue3";
import Layout from "../../Layouts/Admin.vue";
import Form from "../../components/Form.vue";
import FormRow from "../../components/Forms/FormRow.vue";
import FormCheckboxRadio from "../../components/Forms/FormCheckboxRadio.vue";
import Row from "../../components/_partes/RowComponent.vue";
import Col from "../../components/_partes/ColComponent.vue";
import CheckboxRadio from "../../components/_partes/CheckboxRadioComponent.vue";
import Checkbox from "../../components/_partes/CheckboxComponent.vue";
import Input from "../../components/_partes/InputComponent.vue";
import Select from "../../components/_partes/SelectComponent.vue";
import Icon from "../../components/_partes/IconComponent.vue";
import axios from "axios";

export default {
    components: {
        Layout,
        Form,
        FormRow,
        FormCheckboxRadio,
        Row,
        Col,
        CheckboxRadio,
        Checkbox,
        Input,
        Select,
        Icon,
    },
    props: ["dados"],
    data() {
        return {
            acesso: this.dados ? this.dados : {
                cliente_id: 1,
                numero_linha: "(35) 3267-2153",
                operadora_id: 1,
                situacao_id: 1,
                desconto: 9.99,
                valor_total: 90,
                dia_vencimento: 10,
                observacao: "Fixo Ze",
                amplimax: 0,
                box: 0,
                roteador: 0,
                ip_acesso_equipamento: "127.0.0.1",
                created_at: null,
                updated_at: "2022-03-15T20:19:59.000000Z",
                cliente: {
                    id: 1,
                    nome_razao_social: "Manuel Roberto Pedro Henrique Almada",
                    cpf_cnpj: "645.474.180-04",
                    data_nascimento_abertura: "1995-01-11",
                    situacao_id: 1,
                    endereco: "Rua Aureliano Prado",
                    numero: "296",
                    bairro: "Centro",
                    cidade: "Paraguaçu",
                    UF: "MG",
                    CEP: "37120-000",
                    contato_um: "(35) 98870-2794",
                    contato_dois: "(35) 98870-2795",
                    RG: "43.732.666-4",
                    email: "manuelrobertoalmada@fixacomunicacao.com.br",
                    nome_mae: "Emilly Nicole Caroline",
                    envio_whatsapp: true,
                    envio_email: true,
                    envio_correios: true,
                    nome_representante_legal: null,
                    cpf_cnpj_representante_legal: null,
                    contato_representante_legal: null,
                    vendedor_id: 1,
                    situacao_financeira_id: 5,
                    created_at: '2022-03-15T20:19:58.000000Z',
                    updated_at: "2022-03-15T20:19:58.000000Z",
                    deleted_at: null
                },
                operadora: {
                    id: 1,
                    operadora: "Oi",
                    tipo_plano: "Oi Fixo",
                    descricao_plano: "Ilimitado Ligação Internacional Controle",
                    voz: "Ilimitado",
                    dados: "Não Possui",
                    valor_plano: "99,99",
                    created_at: "2022-03-15T20:19:59.000000Z",
                    updated_at: "2022-03-15T20:19:59.000000Z"
                },
                status: {
                    id: 1,
                    tipo: "situacao",
                    status: "Ativo",
                    created_at: "2022-03-15T20:19:58.000000Z",
                    updated_at: "2022-03-15T20:19:58.000000Z"
                }
            },
            pf: true,
            cpf_cnpj_validado: this.dados ? true : false,
            situacao:[],
            situacao_financeira: [],
        };
    },
    methods: {
        toggleForm() {
            switch(event.target.getAttribute("id")){
                case "null":
                    this.acesso.tipo_amplimax = null
                break;    
                case "amplimax":
                    this.acesso.tipo_amplimax = "amplimax"
                break;                
                case "box":
                    this.acesso.tipo_amplimax = "box"
                break;                
                case "roteador":
                    this.acesso.tipo_amplimax = "roteador"
                break;
            }
        },
        salvar(e) {
            if (this.$refs.form.validateForm(e.target)) {
                if (this.acesso.id) {
                    this.$inertia.put(
                        route("acessos.update", { id: this.acesso.id }),
                        this.acesso,
                        {
                            preserveScroll: true,
                            resetOnSuccess: false,
                        }
                    );
                } else {
                    this.acesso.vendedor_id = this.user.id;
                    this.$inertia.post(route("acessos.store"), this.acesso, {
                        preserveScroll: true,
                        resetOnSuccess: false,
                    });
                }
            }
            if (!this.cpf_cnpj_validado) {
                alert("CPF/CNPJ Inválido");
            }
        },
        async filterStatus(){
            const self = this
            let { data } = await axios.get(route("status"));
            data.forEach(function(status){
                if(status.tipo == "situacao"){
                    self.situacao.push({
                        value: status.id,
                        descricao: status.status,
                        bool: self.acesso.situacao_id == status.id ? true : false
                    })
                }
            })
        },
        changeValor(event){
            let nameInput = event.target.name
            let valor = parseFloat(event.target.value.replace(',','.'))
            let valor_plano = parseFloat(this.acesso.operadora.valor_plano.replace(',','.'))
            if(nameInput == "desconto"){
                let calculo = valor_plano - valor
                this.acesso.valor_total = calculo.toLocaleString('pt-br', {minimumFractionDigits: 2})
            }
        },
        async consultarCliente(){
            let { data } = await axios.get(route("todosClientes"));
            console.log(data)
        },
        async consultarOperadora(){
            let { data } = await axios.get(route("todasOperadoras"));
            console.log(data)
        }
    },
    async mounted() {
        this.acesso.operadora.valor_plano = this.acesso.operadora.valor_plano.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.acesso.valor_total = this.acesso.valor_total.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.acesso.desconto = this.acesso.desconto.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.acesso.created_at = this.acesso.created_at ? this.acesso.created_at.split('T')[0].split('/').reverse().join('-') : this.acesso.created_at
        this.filterStatus()
    },
    setup() {
        //console.log(usePage().props.value)
        const user = computed(() => usePage().props.value.auth.user);
        return { user };
    }
};
</script>

<style>
</style>
