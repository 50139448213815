<template>
    <label>{{label}}</label>
    <input
    :type="type ? type : 'text'"
    :class="className ? className : 'form-control'"
    :required="notRequired ? false : true"
    :placeholder="placeholder ? placeholder : ''"
    :value="modelValue"
    v-mask="mask"
    :masked="mask ? true: false"
    @input="$emit('update:modelValue', $event.target.value)"
    @keyup="$emit('customKeyup',$event)"
    @blur="$emit('blur')"
    @change="$emit('change',$event)"
    :disabled="disabled"
    :name="name"
    >
</template>

<script>
import { maska } from 'maska'


export default {
    directives: { 'mask':maska },
    emits: ["customKeyup","update:modelValue",'blur','change'],
    props: [
        "mask",
        "label",
        "modelValue",
        "type",
        "placeholder",
        "className",
        "notRequired",
        "disabled",
        "name"
    ],
    data() {
        return {
            content: this.value,
        };
    },
    methods: {
        handleInput() {
            this.$emit("input", this.content);
        },
    },
};
</script>

<style>
.form-control {
    border: 2.5px solid var(--brax-secondary);
    border-radius: 10px;
}

.form-control:focus {
    border: 2.5px solid var(--brax-secondary);
    box-shadow: 0 0 0 0.25rem rgb(255 110 14 / 25%);
    border-radius: 10px;
}
</style>
