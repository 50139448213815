<template>
    <div id="login">
        <Form id="form-login" @submit.prevent="login" ref='form'>
            <div class="text-center">
                <img class="img-fluid" src="../../../img/logo.png" style="height:100px;margin-bottom: 20px;">
            </div>
            <div class="alert alert-danger" role="alert" v-for="error, errorKey in errors" :key="errorKey">
                {{error}}
            </div>
            <FormRow classResponsive="col-sm-12">
                <Input placeholder="Usuario ou CPF" type="text" v-model="form.usuario"/>
            </FormRow>
            <FormRow classResponsive="col-sm-12">
                <Input placeholder="Senha" type="password" v-model="form.senha"/>
            </FormRow>
            <FormRow classResponsive="col-sm-12" />
            <div class="form-group col-md-12 d-flex justify-content-center">
                <button class="btn btn-grad btn-block mt-2 p-2" type="submit">Entrar</button>
            </div>
        </Form>
    </div>
</template>

<script>
import Input from "../components/_partes/InputComponent.vue";
import Form from "../components/Form.vue";
import FormRow from "../components/Forms/FormRow.vue";
export default {
    components: {
        Input,
        Form,
        FormRow,
    },
    props: {
        errors: Object,
    },
    data() {
        return {
            form: {
                usuario: "admin",
                senha: "123",
            },
        };
    },
    methods: {
        login(e) {
            if(this.$refs.form.validateForm(e.target)){
                this.$inertia.post(route("login.post"), this.form, {
                    preserveScroll: true,
                    resetOnSuccess: false,
                });
            }
        },
    },
};
</script>

<style scoped>
#form-login {
    min-width: 500px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: black;
    padding: 15px;
    color: white;
    border-radius: 10px;
}
#form-login > * {
    padding: 10px;
}
@media (max-width: 500px) {
    #form-login {
        min-width: 90%;
    }
}

#form-login button {
    color: white;
    background: var(--brax-secondary);
    width: 50%;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 10px;
}

#form-login input {
    text-align: center;
}

#login {
    position: absolute;
    background: var(--brax-secondary);
    width: 100%;
    height: 100%;
}
</style>
