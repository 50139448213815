<template>
    <div class="form-group col-md-12 d-flex justify-content-center">
        <button class="btn btn-grad btn-block mt-2 p-2" type="submit">Entrar</button>
    </div>
</template>

<script>
export default {
    props: ['classDiv','classElement']
};
</script>

<style>
</style>
