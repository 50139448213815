<template>
    <div class="container-fluid">
        <div id="mySidebar" class="sidebar" @focus.self="toggle()">
            <a href="javascript:void(0)" class="closebtn sidebar-link" @click="toggle()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </a>
            <a href="javascript:void(0)" class="sair sidebar-link" @click="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z" />
                    <path fill-rule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                </svg>
            </a>

            <a :href="route('dashboard')" class="logo sidebar-link"><img src="../../../img/logo.png"></a>

            <div class="menu" v-for="menu, menuKey in menus" :key="menuKey">
                <Link class="sidebar-link" :data-bs-toggle="menu.collapse ? 'collapse' : ''" :href="menu.collapse ? menu.collapse : route(menu.rota)" :class="activeSubmenuMenu(menu.rota) ? 'active' : ''">
                {{menu.nome}}
                </Link>
                <div class="submenu" v-if="menu.collapse">
                    <div class="collapse" :id="menu.collapse.split('#')[1]" :class="activeSubmenuMenu(menu.rota) ? 'active show' : ''">
                        <div class="sidebar-collapse">
                            <ul>
                                <li :class="route().current(submenu.rota) ? 'active' : ''" v-for="submenu, submenuKey in menu.submenus" :key="submenuKey">
                                    <Link :href="route(submenu.rota,submenu.params)">
                                    {{submenu.nome}}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="abrir-sidebar" class="abrir-sidebar" @click="toggle()">
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    components: {
        Link,
    },
    data() {
        return {
            isOpen: true,
            menus: [
                {
                    nome: "PAINEL PRINCIPAL",
                    rota: "dashboard",
                },
                {
                    nome: "FINANCEIRO",
                    rota: ["financeiro"],
                    collapse: "#financeiro",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "financeiro.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "CLIENTES",
                    rota: ["clientes", "acessos"],
                    collapse: "#clientes",
                    submenus: [
                        {
                            nome: "NOVO CLIENTE",
                            rota: "clientes.cadastro",
                            params: { id: "Novo" },
                        },
                        {
                            nome: "CLIENTES",
                            rota: "clientes.index",
                        },
                        {
                            nome: "NOVO ACESSO",
                            rota: "acessos.cadastro",
                            params: { id: "Novo" },
                        },
                        {
                            nome: "ACESSOS",
                            rota: "acessos.index",
                        },
                    ],
                },
                {
                    nome: "ESTOQUE",
                    rota: ["estoque"],
                    collapse: "#estoque",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "estoque.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "DISTRIBUIÇÃO DE CHIPS",
                    rota: ["distribuicao_chips"],
                    collapse: "#distribuicao_chips",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "distribuicao_chips.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "ORDEM DE SERVIÇO",
                    rota: ["ordem_servico"],
                    collapse: "#ordem_servico",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "ordem_servico.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "FUNIL DE VENDAS",
                    rota: ["funil_vendas"],
                    collapse: "#funil_vendas",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "funil_vendas.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "RELATÓRIOS",
                    rota: ["relatorios"],
                    collapse: "#relatorios",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "relatorios.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "CADASTRO",
                    rota: ["cadastro"],
                    collapse: "#cadastro",
                    submenus: [
                        {
                            nome: "NOVO USUARIO",
                            rota: "usuario.cadastro",
                            params: { id: "Novo" },
                        },
                        {
                            nome: "USUARIOS",
                            rota: "usuario.index",
                            params: { id: "Novo" },
                        },
                    ],
                },
                {
                    nome: "FERRAMENTAS",
                    rota: ["ferramentas"],
                    collapse: "#ferramentas",
                    submenus: [
                        {
                            nome: "FRENTE CAIXA",
                            rota: "ferramentas.cadastro",
                            params: { id: "Novo" },
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        toggle() {
            if (this.isOpen) {
                document.getElementById("mySidebar").style.width = "250px";
                document.getElementById("main").style.marginLeft = "250px";
                document.getElementById("abrir-sidebar").style.width = "0px";
            } else {
                document.getElementById("mySidebar").style.width = "0";
                document.getElementById("main").style.marginLeft = "0";
                document.getElementById("abrir-sidebar").style.width = "10px";
            }
            this.isOpen = !this.isOpen;
        },
        logout() {
            if (confirm("Deseja Realmente Sair?")) {
                this.$inertia.post(route("logout"));
            }
        },
        activeSubmenuMenu(rota) {
            if (Array.isArray(rota)) {
                let retorno = false
                rota.forEach((element) => {
                    if (route().current(element) || route().current(element + ".*")) {
                        retorno = true;
                    }
                });
                return retorno;
            } else {
                if (route().current(rota) || route().current(rota + ".*")) {
                    return true;
                }
                return false;
            }
        },
    },
    mounted() {
        if (this.isOpen) {
            document.getElementById("mySidebar").style.width = "250px";
            document.getElementById("main").style.marginLeft = "250px";
            document.getElementById("abrir-sidebar").style.width = "0px";
        } else {
            document.getElementById("mySidebar").style.width = "0";
            document.getElementById("main").style.marginLeft = "0";
            document.getElementById("abrir-sidebar").style.width = "10px";
        }
    },
};
</script>

<style>
.menu > a {
    font-size: 16px;
}

.logo > img {
    height: 60px;
    width: 80%;
}
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--brax-primary);
    overflow-x: hidden;
    transition: 0.5s;
}

.sidebar-link {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
}

.sidebar-link:not(.active):hover {
    color: var(--brax-secondary);
}

.sidebar-collapse li:not(.active) > :hover {
    color: var(--brax-secondary);
}

.sidebar-sublink:hover {
    color: white;
}

.sidebar-collapse > ul > li {
    padding: 15px 15px 15px 30px;
}

.sidebar-link.active {
    color: var(--brax-primary);
    background: var(--brax-secondary);
    font-weight: 900;
}

.sidebar-sublink {
    text-decoration: none;
}

.sidebar-collapse > ul {
    list-style-type: none;
    padding: 0px;
}

.sidebar-collapse > ul > li.active {
    color: var(--brax-primary);
    background: var(--brax-secondary);
    font-weight: 900;
}

.sidebar-collapse > ul > li.active > a {
    text-decoration: none;
    color: var(--brax-primary);
}
.sidebar-collapse > ul > li:not(.active) > a {
    text-decoration: none;
    color: white;
}

.abrir {
    color: white;
    top: calc(50% - 10px);
    position: absolute;
}

.abrir-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--brax-secondary);
    overflow-x: hidden;
    transition: 0.5s;
    cursor: pointer;
}

.closebtn > svg {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-left: 50px;
    width: 35px;
}

.sair > svg {
    position: absolute;
    top: 40px;
    right: 0px;
    margin-left: 50px;
    width: 30px;
}
</style>
