<template>
<div id="root">
    <Sidebar ref="sidebar"/>
    <div id="main">
        <div class="container-fluid">
            <slot/>
        </div>
    </div>
</div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
export default {
    components:{
        Sidebar
    }
}
</script>

<style>

#main {
  transition: margin-left .5s;
  padding: 16px;
}
</style>
