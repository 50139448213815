<template>
    <div class="form-row">
        <div class="form-group" :class="classResponsive">
            <slot/>
        </div>
    </div>
</template>

<script>
import Input from '../_partes/InputComponent.vue'
export default {
    components:{
        Input,
    },
    props: ['classResponsive']
};
</script>

<style>
</style>
