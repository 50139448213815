<template>
    <div class="form-check" :class="className">
        <slot/>
    </div>
</template>

<script>
export default {
    props: ['className']
}
</script>

<style>

.form-check-input:checked {
    background-color: var(--brax-secondary);
    border-color: var(--brax-secondary);
}

.form-check-input:focus {
  border-color: var(--brax-secondary);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 110 14 / 25%);
}

</style>
