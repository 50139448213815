<template>
    <form action="" class="needs-validation" novalidate>
        <slot />
    </form>
</template>

<script>
export default {
    methods: {
        validateForm(form) {
            form.classList.add("was-validated");
            if(!form.checkValidity()){
                return false;
            }else{
                return true;
            }
            // form.addEventListener(
            //     "submit",
            //     function (event) {
            //         event.preventDefault();
            //         if (!form.checkValidity()) {
            //             event.preventDefault();
            //             event.stopPropagation();
            //         }

            //         form.classList.add("was-validated");
            //     },
            //     false
            // );
        },
    },
};
</script>

<style>
</style>
