<template>
    <div :class="responsividade()">
        <slot/>
    </div>
</template>

<script>
export default {
    props: ['responsive'],
    methods:{
        responsividade(){
            if(this.responsive){
                const r = this.responsive
                return `col-${r[0]} col-sm-${r[1]} col-md-${r[2]} col-lg-${r[3]} col-xl-${r[4]}`
            }else{
                return 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
            }
        }
    }
}
</script>

<style>

</style>
